<template>
  <div>
    <!-- 头部部分 -->
    <div class="titlebox">
      <div class="title">销售产品管理</div>
      <!-- 日期选择 -->
      <div class="block">
        <span>起止日期：</span>
        <el-date-picker
          v-model="Seletime"
          clearable
          type="daterange"
          align="right"
          unlink-panels
          value-format="yyyy-MM-dd"
          @blur="noblurClick"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <!-- 搜索 -->
      <div>
        <el-row :gutter="20">
          <!-- <el-col :span="4"
            ><el-button v-print="printObj" class="export"
              >打印</el-button
            ></el-col
          > -->
          <el-col :span="16">
            <el-input
              placeholder="输入商品型号搜索"
              prefix-icon="el-icon-search"
              v-model="limit.goods_type"
            >
            </el-input>
          </el-col>
          <el-col :span="4"
            ><el-button class="addButton-class" @click="searchClick"
              >搜索</el-button
            ></el-col
          >
        </el-row>
      </div>
      <el-button @click="handleDownload" class="addButton-class">批量导出</el-button>
    </div>
    <!-- 表格部分 -->
    <el-table
      :data="tableData"
      stripe
      class="table-all"
      style="width: 100%"
      element-loading-text="拼命加载中"
      element-loading-background="#fff"
      border
    >
      <el-table-column label="日期" show-overflow-tooltip align="center" width="80">
        <template scope="scope">
          {{ scope.row.create_time | timeFilter }}
        </template>
      </el-table-column>
      <el-table-column prop="no" label="商品图片" align="center">
        <template scope="scope">
          <el-image :src="scope.row.goods_url | replaceUrl"></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="bar_code"
        label="条形码"
        align="center"
        width="80"
      ></el-table-column>
      <el-table-column label="二维码" align="center" width="100">
        <template scope="scope">
          <vue-qr
            v-if="scope.row.qr_code"
            :margin="10"
            :text="scope.row.qr_code"
            :size="80"
          ></vue-qr>
          <div v-else>暂无数据</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="aname"
        label="所属区域"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="dname"
        label="所属部门"
        align="center"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="warehouse_name"
        width="100"
        label="商场名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sell_name"
        width="80"
        label="兑奖员工"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="reward"
        label="奖金"
        width="70"
        align="center"
      ></el-table-column>
      <el-table-column label="兑奖照片" align="center">
        <template slot-scope="scope">
          <el-button @click="delClick(scope.row.upload_url)" type="text" size="small"
            >点击查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_type"
        width="80"
        label="型号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_brand"
        label="品牌"
        width="70"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_norms"
        label="规格"
        width="70"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_class"
        label="类别"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_color"
        label="颜色"
        width="70"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sell_num"
        label="数量"
        width="60"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sell_price"
        label="金额"
        width="90"
        align="center">
        <template slot-scope="scope">
          <div @dblclick="cellOneClick(scope.$index,scope.row)">
              <el-input 
                  v-if="scope.row.editStatus"
                  size="small" 
                  v-model="scope.row.sell_price" ></el-input>
              <span v-else>{{scope.row.sell_price}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        width="100"
        align="center">
        <template slot-scope="scope">
          <div @dblclick="cellOneClick(scope.$index,scope.row)">
              <el-input 
                  v-if="scope.row.editStatus"
                  size="small" 
                  v-model="scope.row.remark" ></el-input>
              <span v-else>{{scope.row.remark}}</span>
          </div>
        </template>

        </el-table-column>
      <el-table-column label="审核" align="center">
        <template slot-scope="scope">
          <div class="type">
            {{
              scope.row.status == 0
                ? "未审核"
                : scope.row.status == 1
                ? "已审核"
                : scope.row.status == 2
                ? "审核不通过"
                : "通过上传凭证"
            }}
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button v-if="scope.row.editButton" @click="editClick(scope.$index,scope.row)" type="text" size="small">保存</el-button>
        </template>
      </el-table-column>
      
    </el-table>

    <!-- <div ></div> -->
    <!-- <el-table
      id="printTest"
      :data="tableData"
      stripe
      style="width: 100%; "
      element-loading-text="拼命加载中"
      element-loading-background="#fff"
      border
    >
      <el-table-column label="日期" align="center" width="100">
        <template scope="scope">
          {{ scope.row.create_time | timeFilter }}
        </template>
      </el-table-column>
      <el-table-column
        prop="bar_code"
        label="条形码"
        align="center"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="warehouse_name"
        label="商场名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sell_name"
        label="兑奖员工"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="reward"
        label="奖金"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_type"
        label="型号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_brand"
        label="品牌"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_norms"
        label="规格"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_class"
        label="类别"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_color"
        label="颜色"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sell_num"
        label="数量"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sell_price"
        label="金额"
        align="center"
      ></el-table-column>
            <el-table-column
        prop="aname"
        label="所属区域"
        align="center"
      ></el-table-column>
            <el-table-column
        prop="dname"
        label="所属部门"
        align="center"
        width="100"
      ></el-table-column>
    </el-table> -->
    <!-- 分页 -->
    <el-pagination
      class="table-limit"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="pageArray"
      :page-size="this.limit.page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <!-- 底部部分 -->
    <div class="bom">
      <div class="left">销售总金额：{{ totalnum.total_price?totalnum.total_price:'0' }}元</div>
      <div class="right">销售总数量：{{ totalnum.total_sum?totalnum.total_sum:'0' }}件</div>
      <div class="left">销售退货总金额：{{ tuihuo.back_price ?totalnum.back_price:'0'}}元</div>
      <div class="right">销售退货总数量：{{ tuihuo.back_num?totalnum.back_num:'0' }}件</div>
    </div>
            <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogTableVisible" width="500px">
      <template>
         <el-image class="img" :src="dialogimg"></el-image>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { sale ,edit} from "@/api/promoting.js";
export default {
  data() {
    return {
      pageArray:this.$store.state.pageArray,
      limit: {
        page: 1,
        page_size: 10,
        goods_type: "",
        create_time: "",
      },
      total: 0,
      loading: true,
      currentPage: 1,
      tableData: [],
      totalnum: {},
      dialogTableVisible:false,
      dialogimg:'',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      tuihuo:{},
      Seletime: [],
      printObj: {
        id: "printTest",//打印区域 Dom ID
        popTitle: '',
        extraCss: ' ',
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>,<style> #printTest { height: auto !important;width:100% !important } <style>'  ,//  可以传进去  style tag 标签；注意要逗号分隔   解决特定区域不显示问题；
      }
    };
  },
  created() {
    this.getMtime();
    // 获取首页数据
    this.getinfo();
  },
  methods: {
    // 时间选择获取最近一个月
    getMtime() {
      let end = new Date();
      let nowend = this.formatDate(end);
      let start = new Date();
      start.setDate(1);
      start.setHours(0);
      start.setSeconds(0);
      start.setMinutes(0);
      start = start.getTime();
      let nowstart = this.formatDate(start);
      this.Seletime = [nowstart, nowend];
      let timeMerge = this.Seletime[0] + "~" + this.Seletime[1];
      this.limit.create_time = timeMerge;
    }, // 格式化日期

    // 单元格双击
    cellOneClick(row, column, cell, event){
        this.tableData[row].editStatus = true
        this.tableData[row].editButton = true
    },


    // 导出数据
    handleDownload() {
        import('@/vendor/Export2Excel').then(excel => {
          // const multiHeader =
          // [
          //   ['对账清单','', '', '', '', '','', '', '', '', ''],
          //   ['结算单位:',this.name, '', '', '', '','', '结算日期:', this.brginTime, '至', this.endTime],
          //   ['日期', '上期结存', '', '本期进货', '', '本期退货','', '本期销售', '', '本期库存', ''],
          // //   ['', '姓名', '地址', '', '', ''] //第二行
          // ] // 前两行的表头数据，二维数组，不够的用空白补全
          const header = ['日期', '条形码', '所属区域', '所属部门', '商场名称', '兑奖员工', '奖金', '型号', '品牌', '规格','类别','颜色','数量','金额'] // 最后一行的表头数据
          const filterVal = ['create_time', 'bar_code', 'aname', 'dname', 'warehouse_name', 'sell_name','reward','goods_type','goods_brand','goods_norms','goods_class','goods_color','sell_num','sell_price']
          const list = this.tableData
          const data = this.formatJson(filterVal, list)
          // console.log(data)
          // return
          // const merges = ['A1:K1','A3:A4','B3:C3','D3:E3','F3:G3','H3:I3','J3:K3'] // 合并单元格的数据，如何合并参考上面图片的蓝色背景部分
          excel.export_json_to_excel({
            // multiHeader,
            header,
            // merges,
            data,
            filename:'销售产品清单',
          })
        })
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },

    formatDate(date) {
      var date = new Date(date);
      var YY = date.getFullYear() + "-";
      var MM =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      return YY + MM + DD;
    },
    noblurClick(e) {
      let timeMerge = this.Seletime[0] + "~" + this.Seletime[1];
      this.limit.create_time = timeMerge;
      this.getinfo();
    },
    delClick(e){
      this.dialogimg = this.$store.state.baseUrl +  e
      this.dialogTableVisible = true

    },
    // 保存按钮
    editClick(row, column, cell, event){
        let data = {
            id:column.id,
            sell_price: column.sell_price,
            remark: column.remark
        }
        this.tableData[row].editStatus = false
        this.tableData[row].editButton = false
        edit(data).then(res=>{})
    },
    // 点击搜索
    searchClick(){
        this.limit.page = 1
        this.getinfo()
    },
    //获取列表信息
    getinfo() {
      sale(this.limit).then((res) => {
        let data = res.data.sell.data;
        if(data){
          data.forEach(element => {
              element.editStatus = false
              element.editButton = false
          });
        }
        let data2 = res.data.count;
        this.tableData = data;
        this.totalnum = data2;
        this.tuihuo = res.data.back;
        this.total = res.data.sell.total;
        this.loading = false;
      });
    },
    //分页
    handleSizeChange(val) {
      this.limit.page_size = val;
      this.loading = true;
      this.getinfo();
    },
    handleCurrentChange(val) {
      this.limit.page = val;
      this.loading = true;
      this.getinfo();
    },
    changetime(e) {
      console.log(123);
    },
  },
};
</script>
<style scoped>
.titlebox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.titlebox .title {
  font-size: 24px;
  font-weight: bold;
  color: #333333;
}
.titlebox .addButton-class {
  background: #2290ff;
  color: #fff;
  border: none;
}
.type {
  color: #2290ff;
}
.bom {
  height: 100px;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}
.bom div {
  height: 65px;
  padding: 0 20px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 23px;
}
.bom .left {
  background: linear-gradient(90deg, #ff774e, #ff4c16);
  box-shadow: 0px 5px 30px 0px rgba(255, 77, 23, 0.4);
}
.bom .right {
  background: linear-gradient(90deg, #ff861b, #ffb527);
  box-shadow: 0px 5px 30px 0px rgba(255, 142, 66, 0.4);
}
.img{
  width: 500px;
  height: 500px;
  margin-bottom: 50px;
  display: block;
  margin: 0 auto;
}
.export{
  background: linear-gradient(90deg, #ff861b, #ffb527);
  box-shadow: 0px 5px 30px 0px rgba(255, 142, 66, 0.4);
  border-radius: 6px;
  font-size: 24px;
  color: #ffffff;
  border:none;
  margin-right: 80px;

}
</style>