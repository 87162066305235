import service from '@/utils/request.js'

// 导入签名计算
import { encrypt } from '@/utils/encrypt'


/**************************************
 * 销售产品管理
 **************************************/
/**
 * 销售产品列表
 */
export function sale(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/sale/index',
        method: 'get',
        params:data
    })
}

/**
 * 
 * @param {编辑} data 
 */

export function edit(data={}) {
    data.sign = encrypt(data);
    return service.request({
        url: '/admin/sale/edit',
        method: 'post',
        params:data
    })
}